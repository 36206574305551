import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 首页文章
const articleIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/article/index', params)
}
// 全部文章
const articleList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/article/list', params)
}
// 文章详情
const articleDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/article/detail', params)
}

// 获取领导班子
const getLeaderList = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/plate/leader')
}

// 收藏
const collect = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/collect',params)
}

const isCollect = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/isCollect',params)
}

const unCollect = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/unCollect',params)
}


// 点赞
const praise = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/praise',params)
}

const isPraise = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/isPraise',params)
}

const unPraise = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/unPraise',params)
}

// 阅读加爱心值
const articleRead = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/article/read',params)
}

// 分享加爱心值
const articleShare = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/article/share',params)
}

// 职衔公示-列表
const groupList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/group_title/list',{params})
}

// 职衔公示-详情
const groupDetails = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/group_title/details',{params})
}

// 荣誉榜-列表
const honorList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/honor/list',{params})
}

export default {
	articleIndex,
	articleList,
	articleDetail,
	getLeaderList,
	collect,
	isCollect,
	unCollect,
	praise,
	isPraise,
	unPraise,
	articleRead,
	articleShare,
	groupList,
	groupDetails,
	honorList
}
