<template>
	<div class="newJobDetail">
		<w-navTab titleText="详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">
				<div class="header-title">{{detail.title}}</div>
				<div class="header-buttom">
					<div class="header-buttom-text">上饶市平安义警协会</div>
					<div class="header-buttom-time">{{detail.create_time}}</div>
				</div>
				<div class="header-time"></div>
			</div>
			<div class="body">
				<div class="body-content"v-html="detail.content" v-if="matchs == ''"></div>
				<div class="body-content-footer" v-for="item in matchs" v-else>
					<div class="text" @click="onDownload(item.url)">{{item.title}}</div>
					<div class="btn" @click="onDown(item.url)">查看</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NewsApi from '@/api/news';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	
	export default{
		name: 'newJobDetail',
		data(){
			return{
				loadingPage: true,
				id: '',
				detail: '',
				matchs: [],
			}
		},
		created() {
			this.id = this.$route.params.id
			this.getNewJobDetail()
		},
		methods:{
			onDownload(url){
				window.open(url)
			},
			onDown(url){
				window.open(url)
			},
			getNewJobDetail(){
				NewsApi.groupDetails({id: this.id})
					.then(result => {
						this.detail = result.data
						this.loadingPage = false
						
						let reg = /<a\b[^>]+\bhref=\"([^\"]*)\"[^>]*>([\s\S]*?)<\/a>/ig;
						
						let pattern = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
						
						let times = result.data.content.match(reg);
						let matchs = []
						if (times) {
							times.forEach((item, key) => {
								
								let temp = {
									url: item.replace(reg, "$1"),
									title: item.replace(reg, "$2")
								}
						
								let suffix = temp.url.split('.').pop().toLowerCase()
						
								temp.suffix = suffix
						
								// 添加到匹配结果
								matchs.push(temp)
								// 将原有链接替换为空
								
								// result.data.article.content = result.data.article.content
								// 	.replace(item, '')
							})
						}
						this.matchs = matchs
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components: {
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.newJobDetail{
		width: 100%;
		min-height: 100vh;
		background-color: #FFF;
		
		.container{
			padding: 0 20px;
			box-sizing: border-box;
			
			.header{
				padding: 16px 0 8px;
				border-bottom: 1px solid #F4F4F4;
				box-sizing: border-box;
				
				.header-title{
					font-size: 18px;
					font-weight: bold;
					line-height: 27px;
					color: #333;
				}
				.header-buttom{
					margin-top: 8px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					
					.header-buttom-text{
						font-size: 13px;
						line-height: 19px;
						color: #999;
					}
					.header-buttom-time{
						font-size: 13px;
						line-height: 19px;
						color: #999;
					}
				}
			}
			.body{
				padding: 16px 0;
				// border-bottom: 1px solid #ddd;
				box-sizing: border-box;
				
				.body-content{
					font-size: 15px;
					line-height: 28px;
					color: #555;
				}
				.body-content-footer{
					padding-bottom: 15px;
					font-size: 16px;
					color: #222;
					overflow: hidden;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;
					
					.text{
						width: 260px !important;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						color: rgb(0, 102, 204);
					}
					.btn{
						background-color: #4399ff;
						color: #fff;
						border-radius: 6px;
						text-align: center;
						padding: 0 6px;
						min-width: 40px;
					}
				}
			}
		}
	}
</style>